import React from "react";
import useAsyncEffect from "use-async-effect";
import { ICustomerQuery, ICustomerQueryDetailedResult, getSearchResults } from "../api/searchApi";
import { DetailedResult } from "./DetailedResult";
import { Loader } from "./Loader";

export interface IDetailedResultLoaderProps {
  backToListView(): void;
  detailCustomerId: string;
  setSubpageTitle(subpageTitle: string | undefined): void;
}

export const DetailedResultLoader = ({ backToListView, setSubpageTitle, detailCustomerId }: IDetailedResultLoaderProps) => {
  const [detailedResult, setDetailedResult] = React.useState<ICustomerQueryDetailedResult | undefined>();
  
  useAsyncEffect(async () => {
    const customerQuery: ICustomerQuery = { customerId: detailCustomerId }

    setSubpageTitle("loading...")
    const result = await getSearchResults(customerQuery);
    const detailedResult = result as ICustomerQueryDetailedResult;
    setDetailedResult(detailedResult);
    setSubpageTitle(detailedResult.customers.find(c => c.seed === detailedResult.seed)?.name)
  }, [setSubpageTitle, detailCustomerId]);

  const linkClick = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    backToListView();
  }, [backToListView])

  return <>
    <div className="mt-4 px-4 text-xl">
      <a href="/" className="text-blue-400 font-semibold cursor-pointer" onClick={linkClick}>{"<"} Go back to results</a>
    </div>
    {!detailedResult && <Loader />}
    {detailedResult && <DetailedResult result={detailedResult} />}
  </>
}