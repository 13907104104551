import React from "react";
import { ISearchContext, SearchContext } from "./SearchContext";
import { CustomerSearchResult, ICustomerQuery, IErrorResult, getSearchResults, isEmptyQuery, isSameQuery, queryToSearchParams, searchParamsToQuery } from "../api/searchApi";
import useAsyncEffect from "use-async-effect";
import { useSearchParams } from "react-router-dom";
import { nullToUndefined } from "../util";


export interface ISearchContextProviderProps {
  children: React.ReactNode;
}

interface IQueryResult {
  result?: CustomerSearchResult;
}

const shouldTryNewQuery = (newQuery: ICustomerQuery, query: ICustomerQuery, error?: string) => {
  if (error) {
    return true;
  }

  return !isEmptyQuery(newQuery) && !isSameQuery(query, newQuery);
}

export const SearchContextProvider = ({ children }: ISearchContextProviderProps): React.ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = React.useMemo(() => searchParamsToQuery(searchParams), [searchParams])

  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [searchResult, setSearchResult] = React.useState<IQueryResult>({});
  const [resolvedQuery, setResolvedQuery] = React.useState<ICustomerQuery>();

  const [error, setError] = React.useState<string>();

  const isResolvedQueryMatching = React.useMemo(
    () => (resolvedQuery || false) && isSameQuery(query, resolvedQuery),
    [query, resolvedQuery])

  const detailCustomerId = nullToUndefined(searchParams.get("detailCustomerId"));
  const setDetailCustomerId = React.useCallback((newId?: string) => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (newId) {
      newSearchParams.set("detailCustomerId", newId);
    } else {
      newSearchParams.delete("detailCustomerId");
    }

    setSearchParams(newSearchParams);
  }, [searchParams, setSearchParams])

  const submitNewQuery = React.useCallback((newQuery: ICustomerQuery) => {
    if (!shouldTryNewQuery(newQuery, query, error)) {
      return;
    }

    const shouldReplaceHistory = isSameQuery(newQuery, query);

    setResolvedQuery(undefined);
    setSearchParams(queryToSearchParams(newQuery), { replace: shouldReplaceHistory });

    setSearchResult({});
  }, [setSearchParams, setResolvedQuery, setSearchResult, query, error]);

  const clearSearch = React.useCallback(() => {
    const shouldReplaceHistory = isEmptyQuery(query);

    setSearchParams({}, { replace: shouldReplaceHistory });
    setSearchResult({})
    setResolvedQuery(undefined);
    setError(undefined);
    setIsFetching(false);
  }, [query, setSearchParams, setSearchResult, setResolvedQuery, setError, setIsFetching]);

  useAsyncEffect(async () => {
    if (!isResolvedQueryMatching && !isEmptyQuery(query)) {
      setIsFetching(true);
      const results = await getSearchResults(query);
      setIsFetching(false);

      if (results.hasOwnProperty("error")) {
        const e = (results as IErrorResult).error;
        setError(e);
        setSearchResult({});
        setResolvedQuery(query);
      } else {
        setError(undefined);
        setSearchResult({ result: results })
        setResolvedQuery(query);
      }
    }
  }, [isResolvedQueryMatching, query]);

  const state = React.useMemo<ISearchContext>(() => ({
    query,
    isFetching,
    isSearchPending: isResolvedQueryMatching,
    submitNewQuery,
    searchResult: searchResult.result,
    error,
    detailCustomerId,
    setDetailCustomerId,
    clearSearch,
  }), [query, isResolvedQueryMatching, submitNewQuery, isFetching, searchResult.result, error, detailCustomerId, setDetailCustomerId, clearSearch])

  return (
    <SearchContext.Provider value={state}>
      {children}
    </SearchContext.Provider>
  )
}
