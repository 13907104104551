import React from "react";
import { ICustomerQueryMultipleResult } from "../api/searchApi";


export interface IListResultProps {
  result: ICustomerQueryMultipleResult;
  selectCustomer(customerId: string): void;
}

export const ListResult = ({ result, selectCustomer }: IListResultProps) => {
  return (<div className="p-4">
    <h2 className="text-xl font-semibold">{result.number} results</h2>
    {result.results.length === 0 && result.number > 0 && (
      <div>
        There are too many results to display, please narrow down your search.
      </div>
    )}
    {result.number === 0 && (
      <div>
        No results found for this search
      </div>
    )}
    {result.results.length > 0 && (
    <>
    <p>Click on any result to see that customer's details</p>
    <table>
      <thead>
        <th>Name</th>
        <th>Phone Number</th>
        <th>Email Address</th>
        <th>Address</th>
        <th>Address 2</th>
        <th>Address 3</th>
        <th>Country</th>
        <th>City</th>
        <th>State/Province</th>
        <th>Postal/Zip</th>
        <th>Customer ID</th>
      </thead>
      <tbody>
        {result.results.map((result) => (<tr className="hover:bg-gray-300 cursor-pointer" key={result.seed} onClick={() => selectCustomer(result.seed)}>
          <td>{result.name}</td>
          <td>{result.phone}</td>
          <td>{result.email}</td>
          <td>{result.address[0]}</td>
          <td>{result.address[1]}</td>
          <td>{result.address[2]}</td>
          <td>{result.country}</td>
          <td>{result.city}</td>
          <td>{result.prov}</td>
          <td>{result.postal || result.zip}</td>
          <td>{result.seed}</td>
        </tr>))}
      </tbody>
    </table></>)}
  </div>)
}
