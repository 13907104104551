import React from 'react';
import { SearchContextProvider } from "./context";
import { SearchForm } from "./components/SearchForm";
import { SearchResult } from "./components/SearchResult";
import { ErrorBanner } from './components/ErrorBanner';


function App() {
  return (
    <div>
      <SearchContextProvider>
        <ErrorBanner />

        <header className="px-4 py-4">
          <h1 className="text-xl">DOL Customer Lookup</h1>
        </header>
        <SearchForm />
        <SearchResult />
      </SearchContextProvider>
    </div>
  );
}

export default App;
