
const defaultPageTitle = document.title;

export const setPageTitle = (subpageTitle: string | undefined) => {
  if (subpageTitle) {
    document.title = `${defaultPageTitle} - ${subpageTitle}`
  } else {
    document.title = defaultPageTitle;
  }
}
