import { createContext } from "react";
import { CustomerSearchResult, ICustomerQuery } from "../api/searchApi";


export interface ISearchContext {
  query: ICustomerQuery;
  isFetching: boolean;
  submitNewQuery: (query: ICustomerQuery) => void;
  isSearchPending: boolean;
  searchResult?: CustomerSearchResult;
  detailCustomerId?: string;
  error?: string;
  setDetailCustomerId(detailCustomerId: string | undefined): void;
  clearSearch(): void;
}

const defaultContext: ISearchContext = {
  query: {},
  isFetching: false,
  isSearchPending: false,
  submitNewQuery: () => {},
  setDetailCustomerId: () => {},
  clearSearch: () => {},
}

export const SearchContext = createContext<ISearchContext>(defaultContext);