import React from "react";
import { SearchContext } from "../context";
import { ICustomerQueryDetailedResult, ICustomerQueryMultipleResult } from "../api/searchApi";
import { DetailedResult } from "./DetailedResult";
import { ListResult } from "./ListResult";
import { DetailedResultLoader } from "./DetailedResultLoader";
import { Loader } from "./Loader";
import { setPageTitle } from "../utils/pageTitle";


export const SearchResult = () => {
  const { searchResult, isFetching, detailCustomerId, setDetailCustomerId } = React.useContext(SearchContext);

  const backToListView = React.useCallback(() => setDetailCustomerId(undefined), [setDetailCustomerId])

  const [subpageTitle, setSubpageTitle] = React.useState<string>();

  React.useEffect(() => {
    if (!searchResult) {
      setSubpageTitle(undefined);
    } else if (!searchResult.hasOwnProperty("seed") && !detailCustomerId) {
      const res = searchResult as ICustomerQueryMultipleResult;
      setSubpageTitle(`${res.number} results`)
    } else if (!detailCustomerId) {
      const res = searchResult as ICustomerQueryDetailedResult;
      setSubpageTitle(res.customers.find(c => c.seed === res.seed)?.name)
    }
  }, [searchResult, detailCustomerId])

  React.useEffect(() => {
    setPageTitle(subpageTitle);

    return () => {
      setPageTitle(undefined);
    }
  }, [subpageTitle]);

  if (isFetching) {
    return <Loader />
  }

  if (searchResult === undefined) {
    return <></>;
  }

  if (detailCustomerId) {
    return <DetailedResultLoader detailCustomerId={detailCustomerId} backToListView={backToListView} setSubpageTitle={setSubpageTitle} />
  }

  if (searchResult.hasOwnProperty("seed")) {
    const detailedResult = searchResult as ICustomerQueryDetailedResult;

    return <DetailedResult result={detailedResult}></DetailedResult>
  } else {
    const listResult = searchResult as ICustomerQueryMultipleResult;

    return <ListResult result={listResult} selectCustomer={setDetailCustomerId} />
  }
}
