import React from "react";
import { ICustomerHeaderResult, ICustomerQueryDetailedResult, ILineItem, IOrder, ITrackingInfo } from "../api/searchApi";
import _ from "lodash";


export interface IDetailedResultProps {
  result: ICustomerQueryDetailedResult;
}

interface IDateParsedOrder extends Omit<IOrder, "date"> {
  date: Date
}

const sortedOrders = (orders: IOrder[]): IDateParsedOrder[] => {
  const dateParsedOrders: IDateParsedOrder[] = orders.map(o => ({
    ...o,
    date: new Date(o.date),
  }))

  return dateParsedOrders.sort((a, b) => a.date > b.date ? -1 : 1)
}

interface IAddressBlockProps {
  title: string;
  customer: ICustomerHeaderResult;
}

const AddressBlock = (props: IAddressBlockProps) => {
  return <div className="shadow-lg p-4">
    <h3 className="mb-2 text-lg font-semibold">{props.title}</h3>
    <p>Name: {props.customer.name}</p>
    <p>Phone: {props.customer.phone}</p>
    <p>Email: {props.customer.email}</p>
    <ul className="list-none mt-2">
      {props.customer.address?.map((a, i) => <li key={i}>{a}</li>)}
      <li key="city">{props.customer.city} {props.customer.prov && (props.customer.prov + ",")} {props.customer.country} </li>
      <li key="postal">{props.customer.postal || props.customer.zip}</li>
    </ul>
  </div>
}

const orderStatus = (statusNumber: number) => {
  if (statusNumber === 9) {
    return "Cancelled"
  } else if (statusNumber === 4) {
    return "Completed"
  } else if (statusNumber === 1) {
    return "In Progress"
  }
}

const createTrackingLink = (t: ITrackingInfo) => 
  t.carrierLink.replace("[tracking]", t.trackingNumber);

interface ILineItemsTableRowProps {
  lineItem: ILineItem;
}

const LineItemsTableRow = ({ lineItem }: ILineItemsTableRowProps) => (
  <tr>
    <td>{lineItem.sku}</td>
    <td>{lineItem.qty}</td>
    <td>{lineItem.productType}</td>
    <td>{lineItem.listingCost.toFixed(2)}</td>
    <td>{lineItem.amountCharged.toFixed(2)}</td>
    <td>{lineItem.discount.toFixed(2)}</td>
    <td>{lineItem.description}</td>
  </tr>
)

export const DetailedResult = ({ result }: IDetailedResultProps) => {
  const primaryCustomer = result.customers.find(c => c.seed === result.seed);

  const orders = React.useMemo(() => sortedOrders(result.orders), [result.orders]);

  const [selectedOrder, setSelectedOrder] = React.useState(orders[0]);
  
  const poNumbersAndSetters: [string, () => void][] = React.useMemo(() => orders.map(
    o => ([o.PONumber, () => setSelectedOrder(o)])
  ), [orders, setSelectedOrder]);

  const billingCustomer = result.customers.find(c => c.seed === selectedOrder.billto);
  const shippingCustomer = result.customers.find(c => c.seed === selectedOrder.delvto);

  const profileNotes = React.useMemo(() => 
    result.notes.filter(n => n.message.trim() !== ""), [result.notes])
  
  const sortedNotes = React.useMemo(() => _.sortBy(profileNotes, n => n.seq), [profileNotes]);

  return <div className="p-4 w-full">
    <div className="flex flex-row">
      <div>
        <h2 className="text-xl mb-2">{primaryCustomer?.name} {primaryCustomer?.seed}</h2>
        <p>Phone: {primaryCustomer?.phone}</p>
        <p>Email: <a href={"mailto:" + primaryCustomer?.email}>{primaryCustomer?.email}</a></p>

        {sortedNotes.length > 0 && (<div className="mt-8">
          <h3 className="text-lg font-semibold">Notes</h3>
          <ul>
            {sortedNotes.map(n => (<li key={n.seq}>
              <span className="text-sm">{n.user} on {n.date}:</span><span className="ml-4">{n.message}</span>
            </li>))}
          </ul>
        </div>)}
      </div>
    
      <div className="ml-16 overflow-y-scroll max-h-60">
        <p>Orders</p>
        <ul className="list-none">
          {poNumbersAndSetters.map(([po, setFunc]) => {
            let selectedClass = "hover:bg-gray-100 cursor-pointer"
            if (po === selectedOrder.PONumber) {
              selectedClass = "bg-gray-100 cursor-default"
            }

            return <li className={`${selectedClass} py-1 px-2`} key={po} onClick={setFunc}>{po}</li>
          })}
        </ul>
      </div>
    </div>

    <div className="mt-8">
      <h3 className="text-lg font-semibold">{selectedOrder.PONumber}</h3>
      
      <div className="mt-4 px-8 flex flex-row space-x-8">
        <div>
          <p>Order Date: <span className="font-semibold">{selectedOrder.date.toDateString()}</span></p>
          <p>Order Total: <span className="font-semibold">{selectedOrder.price.toFixed(2)}</span></p>
          <p>Status: <span className="font-semibold">{orderStatus(selectedOrder.status)}</span></p>
          <p>UFE Order Number: <span className="font-semibold">{selectedOrder.order}</span></p>
          <p>Invoice(s): <span className="font-semibold">{selectedOrder.invoices.map(i => i.invoice).join(",")}</span></p>
        </div>
   
        {selectedOrder.picks.length > 0 &&
          <div>
            <h3 className="font-semibold">Shipments</h3>
            <ul className="list-none">
              {selectedOrder.picks.map(pick => <li key={pick.pick}>
                <p>Pick Number: {pick.pick}</p>
                <ul className="list-none">
                  {pick.tracking.map(t => <li key={t.trackingNumber}>
                    {t.carrier} <a className="text-blue-400" target={"_blank"} rel={"noreferrer"} href={createTrackingLink(t)}>{t.trackingNumber}</a>
                  </li>)}
                </ul>
              </li>)}
            </ul>
          </div>
          }
      </div>

      <div className="flex flex-row space-x-8 mt-4">
        {billingCustomer && <AddressBlock title={"Billing Information"} customer={billingCustomer} />}
        {shippingCustomer && <AddressBlock title={"Shipping Information"} customer={shippingCustomer} />}
      </div>

      <table className="mt-4 border-spacing-x-4 border-separate">
        <thead>
          <th>SKU</th>
          <th>QTY</th>
          <th>Type</th>
          <th>Listing Price</th>
          <th>Amount Charged</th>
          <th>Discount</th>
          <th>Description</th>
        </thead>
        <tbody>
          {selectedOrder.items.map((lineItem, index) => (<LineItemsTableRow key={`${selectedOrder.PONumber}--${lineItem.sku}--${index}`} lineItem={lineItem} />))}
        </tbody>
      </table>
    </div>
  </div>
}
