import axios, { AxiosError } from "axios";

export interface ICustomerQuery {
  customerId?: string;
  name?: string;
  email?: string;
  address?: string;
  phone?: string;
  city?: string;
  country?: string;
  zip?: string;
  postal?: string;
  province?: string;
}

export interface ICustomerHeaderResult {
  seed: string;
  name: string;
  phone: string;
  email: string;
  country: string;
  city: string;
  zip: string;
  prov: string;
  postal: string;
  address: string[];
  otherPhones: string[];
  otherEmails: string[];
}

export interface ICustomerQueryMultipleResult {
  number: number;
  results: ICustomerHeaderResult[];
}

export interface IInvoice {
  invoice: string;
  invoiceType: string;
  status: number;
}

export interface ILineItem {
  sku: string;
  description: string;
  description2: string;
  productType: string;
  qty: number;
  listingCost: number;
  amountCharged: number;
  discount: number;
  catalog: string;
}

export interface ITrackingInfo {
  trackingNumber: string;
  carrier: string | null;
  carrierLink: string;
}

export interface IPick {
  pick: string;
  tracking: ITrackingInfo[];
}

export interface IOrder {
  delvto: string;
  billto: string;
  order: string;
  price: number;
  date: string;
  status: number;
  orderType: string;
  PONumber: string;
  invoices: IInvoice[];
  picks: IPick[];
  items: ILineItem[];
}

export interface INote {
  seq: string;
  date: string;
  user: string;
  message: string;
}

export interface ICustomerQueryDetailedResult {
  seed: string;
  orders: IOrder[];
  customers: ICustomerHeaderResult[];
  notes: INote[];
}

const keys: [keyof ICustomerQuery, string][] = [
  ["customerId", "seed"],
  ["name", "name"],
  ["email", "email"],
  ["phone", "phone"],
  ["address", "address"],
  ["city", "city"],
  ["country", "country_id"],
  ["zip", "zip"],
  ["postal", "postal_cd"],
  ["province", "prov_id"],
]

export interface IErrorResult {
  error: string;
}

export type CustomerSearchResult = ICustomerQueryDetailedResult | ICustomerQueryMultipleResult | IErrorResult

export const isEmptyQuery = (customerQuery: ICustomerQuery): boolean => {
  const idx = keys.findIndex(([key, _]) => !!customerQuery[key])

  return idx === -1;
}

export const isSameQuery = (queryA: ICustomerQuery, queryB: ICustomerQuery): boolean =>
  keys.every(([key, _]) => queryA[key] === queryB[key]);

export const searchParamsToQuery = (searchParams: URLSearchParams): ICustomerQuery => {
  const query: ICustomerQuery = {};

  keys.forEach(([key, _]) => {
    const val = searchParams.get(key);

    if (val) {
      query[key] = val;
    }
  });
  
  return query;
}

export const queryToSearchParams = (query: ICustomerQuery): URLSearchParams => {
  const params = new URLSearchParams();

  keys.forEach(([key, _]) => {
    const val = query[key];

    if (val) {
      params.set(key, val);
    }
  });

  return params;
}

export const getSearchResults = async (customerQuery: ICustomerQuery): Promise<CustomerSearchResult> => {  
  console.log("Executing query", customerQuery)
  let body: { [k: string]: string } = {};

  keys.forEach(([key, paramName]) => {
    const val = customerQuery[key];

    if (val) {
      body = { ...body, [paramName]: val };
    }
  });

  try {
    const response = await axios({
      method: "post",
      url: `https://lookupserver.distribution-online.com/api/v2/customer`,
      timeout: 10000,
      responseType: "json",
      data: JSON.stringify(body)
    })
  
    if (response.status < 300) {
      return response.data as CustomerSearchResult;
    } else {
      return {
        error: `Error ${response.status}`
      }
    }
  } catch (e: any) {
    console.log(e);

    if (e instanceof AxiosError) {
      if (e.code === "ERR_NETWORK") {
        return {
          error: "A network error occurred when submitting the search. Please ensure that you are on-site or that you are connected with the VPN."
        }
      }
    }

    return {
      error: `An unknown error occurred: ${e}`
    }
  }
}
