import React from "react";
import { SearchContext } from "../context";


export const ErrorBanner = () => {
  const searchContext = React.useContext(SearchContext);

  return (<>
    {searchContext.error && <div className="bg-red-300 px-4 py-2">
      <p>{searchContext.error}</p>
    </div>}
  </>)
}