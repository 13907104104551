import React from "react";
import { ICustomerQuery } from "../api/searchApi";
import { SearchContext } from "../context";

interface ISearchFormFieldProps {
  field: keyof ICustomerQuery;
  label: string;
  value: string | undefined;
  updateField(field: keyof ICustomerQuery, newValue: string): void;
  onKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void;
}

const SearchFormField = ({ field, label, value, updateField, onKeyDown }: ISearchFormFieldProps) => {
  const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    updateField(field, e.currentTarget.value);
  }, [field, updateField])

  return (
    <div className="flex flex-row" style={{width: 300}}>
      <label className="block">{label}</label>
      <input className="block ml-auto border border-gray-600 border-solid" type="text" value={value || ""} onChange={onChange} onKeyDown={onKeyDown} />
    </div>
  )
}

export const SearchForm = () => {
  const searchContext = React.useContext(SearchContext);
  const { query: currentContextQuery, submitNewQuery } = searchContext;

  const [customerQuery, setCustomerQuery] = React.useState<ICustomerQuery>(currentContextQuery)

  const updateField = React.useCallback((key: keyof ICustomerQuery, value: string) => {
    setCustomerQuery({ ...customerQuery, [key]: value });
  }, [customerQuery, setCustomerQuery]);

  const submitSearch = React.useCallback(() => {
    submitNewQuery(customerQuery);
  }, [customerQuery, submitNewQuery])

  const onKeyDown = React.useCallback((e
    : React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter"){
      submitSearch();
    }
  }, [submitSearch]);

  React.useEffect(() => {
    setCustomerQuery(currentContextQuery)
  }, [currentContextQuery])

  return (
    <div className="px-8">
      <div className="flex flex-row mb-8" style={{width: 640}}>
        <div className="w-1/2 space-y-2">
          <SearchFormField field={"name"} label="Name" value={customerQuery.name} updateField={updateField} onKeyDown={onKeyDown} />
          <SearchFormField field={"phone"} label="Phone" value={customerQuery.phone} updateField={updateField} onKeyDown={onKeyDown} />
          <SearchFormField field={"email"} label="Email Address" value={customerQuery.email} updateField={updateField} onKeyDown={onKeyDown} />
          <SearchFormField field={"address"} label="Address" value={customerQuery.address} updateField={updateField} onKeyDown={onKeyDown} />
          <SearchFormField field={"city"} label="City" value={customerQuery.city} updateField={updateField} onKeyDown={onKeyDown} />
        </div>
        <div className="w-1/2 space-y-2">
          <SearchFormField field={"postal"} label="Postal Code" value={customerQuery.postal} updateField={updateField} onKeyDown={onKeyDown} />
          <SearchFormField field={"zip"} label="Zip" value={customerQuery.zip} updateField={updateField} onKeyDown={onKeyDown} />
          <SearchFormField field={"country"} label="Country" value={customerQuery.country} updateField={updateField} onKeyDown={onKeyDown} />
          <SearchFormField field={"province"} label="Province" value={customerQuery.province} updateField={updateField} onKeyDown={onKeyDown} />
          <SearchFormField field={"customerId"} label="Customer ID" value={customerQuery.customerId} updateField={updateField} onKeyDown={onKeyDown} />
        </div>
      </div>
      <div className="px-8 flex flex-row-reverse" style={{width: 640}}>
      <button className="ml-4 bg-blue-500 text-white font-semibold text-lg px-4 py-2" onClick={submitSearch}>Search</button>
        <button className="bg-gray-300 text-black font-semibold text-lg px-4 py-2" onClick={searchContext.clearSearch}>Clear</button>
      </div>
    </div>
  )
}
